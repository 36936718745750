import React, { useEffect } from "react";
import Layout from "../components/layout";
import { gsap } from "gsap";
// import SunIcon from "../assets/icons/SunIcon.svg";

import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
// console.log("SunIcon", SunIcon);
// const SunIcon = require("./assets/icons/sun.svg");
export default () => {
  gsap.registerPlugin(MotionPathPlugin);
  const tween = () => {
    gsap.from(".sun", {
      duration: 9,
      ease: "circ.in",
      autoAlpha: 1,
      motionPath: {
        path: "#path",
        align: "#path",
        autoRotate: 10,
        alignOrigin: [0.5, 0.5],
      },
    });

    gsap.to(".sun", { delay: 6, duration: 2, display: "none", scale: 0.7 });
  };

  useEffect(() => {
    tween();
  });

  return (
    <Layout>
      <div className="container">
        <h1>MotionPathPlugin (new in GSAP 3)</h1>

        <svg width="350px" height="350px" viewBox="0 0 587 587" id="svg">
          <path
            id="path"
            d="M578.492965,327.930376 C560.082575,470.55421 438.048671,580.894 290.447,580.894 C130.145,580.894 1.13686838e-13,450.749 1.13686838e-13,290.447 C1.13686838e-13,130.145 130.145,1.13686838e-13 290.447,1.13686838e-13 C437.307183,1.13686838e-13 558.855837,109.233975 578.207672,250.816635"
            fill="none"
            stroke="#000000"
          />
          <g
            className="sun"
            transform="translate(498.952481, 79.180856)"
            fill="none"
          >
            {/* <SunIcon /> */}
          </g>
          <g></g>
        </svg>
        <button className="btn gsap-btn mt-12 ml-24" onClick={() => tween()}>
          tween
        </button>
      </div>
    </Layout>
  );
};
